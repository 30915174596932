import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import PageTransition from 'gatsby-v2-plugin-page-transitions'

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            logoName
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.logoName}</Link>
        </div>
        <Navigation />
      </header>
      <PageTransition>
        {children}
      </PageTransition>
      <footer className="site-footer">
        <p>{data.site.siteMetadata.title} © {new Date().getFullYear()}. All rights reserved.</p>
      </footer>
    </div>
  )
}
